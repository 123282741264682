<template>
	<div class="app-container">
        <div class="filter-container" style="display: flex;justify-content: space-between;">
			<div class="filter-item">
				<label class="label">商品名称:</label>
				<el-input
					v-model="goodsName"
					placeholder="请输入商品名称"
					style="width:250px;margin-right: 10px;"
					clearable
					@keyup.enter.native="search"
				></el-input>
                <el-button type="primary" @click="search">查询</el-button>
			</div>
            <div class="filter-item">
				<el-button type="primary" style="width:90px;" @click="lastPage" :disabled='pageNo <= 1'>上一页</el-button>
                当前页数：<el-input v-model="pageNo" style="width: 100px;" @change='getList'></el-input>
                <el-button type="primary" style="width:90px;margin-left:10px;" :disabled='!hasNextPage' @click="nextPage">下一页</el-button>
			</div>
		</div>
		<!-- API商品表格 -->
		<div class="table-container">
            <!-- CPS商品表格 -->
            <div>
                <el-table 
                    :data="tableData" 
                    v-loading="loading" 
                    default-expand-all
                    row-key="uid"
                    border
                    :tree-props="{children: 'skuList'}"
                    style="width: 100%">
                    <el-table-column prop="goodsName" label="商品名称"></el-table-column>
                    <el-table-column prop="outGoodsId" label="商品ID">
                        <template slot-scope="scope">
                            <span v-if="scope.row.name">{{scope.row.outSkuId}}</span>
                            <span v-else>{{scope.row.outGoodsId}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="商品图片">
                        <template slot-scope="scope">
                            <img
                                :src="scope.row.logo+'?x-oss-process=image/resize,m_fixed,h_80,w_80'"
                                style="width:50px;height:50px;"
                            />
                        </template>
                    </el-table-column>
                    <el-table-column prop="costPrice" label="成本价"></el-table-column>
                    <el-table-column prop="salePrice" label="零售价"></el-table-column>
                    <el-table-column prop="stock" label="库存"></el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scop">
                            <button-permissions :datas="'addCpsGoods'">
                                <div class="table-button" v-if="scop.row.name" @click="handleTableEdit(scop.row)">选为商品</div>
                            </button-permissions>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
		</div>
	</div>
</template>

<script>

import { 
    getCpsGoods, 
    cpsValidate
} from '@/api/goods.js';


export default {
    name:'cpsGoods',
	components: {
        
	},
	data () {
		return {
            //必应鸟
            goodsName:'',   //商品名称
			tableData: [],
			pageNo: 1,
			pageSize: 10,
			total: 0,
            loading: false,
            hasNextPage:false,  //是否有下一页
		}
	},
	created () {
        this.getList();
	},
	methods: {


		// 获取列表数据
		async getList () {
            this.loading = true;
            let _this = this;
			try {
				let data = {
                    goodsName:this.goodsName,
					pageNo: this.pageNo,
					pageSize: this.pageSize
				}
				let result = await getCpsGoods(data);
                if (result.success && result.data.list) {

                    result.data.list.map(item =>{
                        item.uid = _this.guid();
                        item.skuList.map(item1 =>{
                            item1.outGoodsId = item.outGoodsId;
                            item1.uid = _this.guid();
                            item1.goodsName = item1.name;
                            item1.help = item1.useNotice;
                        })
                    })

                    this.tableData = result.data.list;
                    this.hasNextPage = result.data.hasNextPage; 
                }else{
                    this.tableData = [];
                    this.hasNextPage = false; 
                }
			} catch (error) {
				console.log(error);
			} finally {
				this.loading = false;
			}
		},
		async handleTableEdit (row) {
            let result = await cpsValidate({outGoodsId:row.outGoodsId,outSkuId:row.outSkuId});
            if (result.success) {
                console.log(row);
                let params = {
                    row: encodeURIComponent(JSON.stringify(row))
                }
                this.publicToDetails(params,'/goods/cps/cpsGoodsDetail',this);
            }else{
                this.$message({
                    showClose: true,
                    type: 'error',
                    message: '当前商品已添加，请至必应鸟商品菜单中编辑！'
                });
            }
        },
        //上一页
        lastPage(){
            this.pageNo--;
            this.getList();
        },
        //下一页
        nextPage(){
            this.pageNo++;
            this.getList();
        },
        //生成唯一ID
        guid() {
            var a = function() {
                return (65536 * (1 + Math.random()) | 0).toString(16).substring(1)
            };
            return 'a' + a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a()
        },
        //搜索
        search(){
            this.pageNo = 1;
            this.getList();
        },
	}
}
</script>

<style lang="less" scoped>
.app-container {
	background: #fff;
}
.table-button {
	color: #409eff;
	display: inline-block;
	margin-right: 10px;
	cursor: pointer;
}

.dialog {
	width: 100%;

	/deep/.el-dialog__header {
		border-bottom: 1px solid #ddd;
	}

	.content {
		padding: 0 30px;
		display: flex;
		flex-direction: row;

		.right {
			margin-left: 20px;
		}

		.text {
			margin-left: 20px;
			font-size: 14px;
			color: #999;
			line-height: 40px;
		}
	}

	.button {
		margin: 0 auto;
		width: 200px;
	}
}

.data-dialog {
	.value {
		line-height: 50px;
		font-size: 24px;
		color: #000;
	}

	.item {
		color: #666;
		margin-top: 10px;
	}
}
</style>
